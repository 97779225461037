import * as _ from 'lodash'
import { FormStrategy } from './form-strategy'
import { FIELDS } from '../../constants/roles'
import { RegistrationFieldPreset } from '../../constants/field-types'
import { escapeRegExp, innerText } from '../viewer-utils'
import { CRM_TYPES } from '../../constants/crm-types-tags'
import * as Raven from 'raven-js'
import { getInputValue } from '../input-value'
import { getFieldType, isNumber } from '../viewer-utils'
import translations from '../../utils/translations'

const ERROR_COLOR = '#FF4040'
const PASSWORD_LENGTH = { MIN: 4, MAX: 15 }
const PREVIEW_MODE = 'Preview'

const getFields = $w => {
  const email: any = loginEmailField($w)
  const password: any = passwordField($w)
  const joinTheCommunityCheckbox: any = joinTheCommunityCheckboxField($w)
  return { email, password, joinTheCommunityCheckbox }
}

const loginEmailField = ($w): any =>
  _($w(`@${FIELDS.ROLE_FIELD_REGISTRATION_FORM_LOGIN_EMAIL}`)).first()
const passwordField = ($w): any => _($w(`@${FIELDS.ROLE_FIELD_REGISTRATION_FORM_PASSWORD}`)).first()
const linkLoginField = ($w): any =>
  _($w(`@${FIELDS.ROLE_FIELD_REGISTRATION_FORM_LINK_TO_LOGIN_DIALOG}`)).first()
const joinTheCommunityCheckboxField = ($w): any =>
  _($w(`@${FIELDS.ROLE_FIELD_REGISTRATION_FORM_CHECKBOX_JOIN_COMMUNITY}`)).first()

const submitFields = fields =>
  fields.filter(field => _.get(field, 'connectionConfig.fieldType') !== 'password')

const IGNORED_FIELDS_WITHOUT_EMAIL = {
  [RegistrationFieldPreset.REGISTRATION_FORM_PASSWORD]: true,
  [RegistrationFieldPreset.REGISTRATION_FORM_CHECKBOX_AGREE_TERMS]: true,
  [RegistrationFieldPreset.REGISTRATION_FORM_CHECKBOX_JOIN_COMMUNITY]: true,
}

const IGNORED_FIELDS = {
  ...IGNORED_FIELDS_WITHOUT_EMAIL,
  [RegistrationFieldPreset.REGISTRATION_FORM_LOGIN_EMAIL]: true,
}

const showExistingAccountMessage = (message, { messageContent }) => {
  if (!_.get(message, 'html')) {
    return
  }

  const colorRegExp = /color: ?[^;"]+/
  let htmlErrorMessage = messageContent

  if (message.html.indexOf(colorRegExp) === -1) {
    htmlErrorMessage = `<span style="color: ${ERROR_COLOR}">${htmlErrorMessage}</span>`
  }

  message.html = message.html
    .replace(colorRegExp, `color: ${ERROR_COLOR}`)
    .replace(new RegExp(`>${escapeRegExp(innerText(message.html))}`), `>${htmlErrorMessage}`)

  message.show()
}

const valueHandlerByType = {
  DatePicker: ({ value }) => value,
}

const getFieldValue = field => {
  const fieldType = getFieldType(field)
  const fieldValue = valueHandlerByType[fieldType]
    ? valueHandlerByType[fieldType](field)
    : getInputValue(field)
  return isNumber(field) ? +fieldValue : fieldValue
}

const buildContactInfo = validFields => {
  const contactInfo = {
    phones: [],
    emails: [],
  }

  validFields.forEach(field => {
    const { crmType, customFieldId, customFieldName } = field.connectionConfig
    const fieldValue = getFieldValue(field)
    switch (crmType) {
      case CRM_TYPES.EMAIL:
        contactInfo.emails.push(fieldValue)
        break
      case CRM_TYPES.PHONE:
        contactInfo.phones.push(fieldValue)
        break
      case CRM_TYPES.CUSTOM_FIELD:
        if (customFieldId) {
          contactInfo[customFieldName] = fieldValue
        }
        break
      default:
        contactInfo[crmType] = fieldValue
    }
  })
  return contactInfo
}

export class RegistrationFormStrategy extends FormStrategy {
  firstInitialization = {
    [FIELDS.ROLE_FIELD_REGISTRATION_FORM_LOGIN_EMAIL]: true,
    [FIELDS.ROLE_FIELD_REGISTRATION_FORM_PASSWORD]: true,
  }

  static isEnabled($w) {
    const { email, password } = getFields($w)
    return email && password
  }

  constructor(submitArgs, linksUtil) {
    super(submitArgs, linksUtil)
    const { $w } = submitArgs

    this.registerPasswordValidation($w)
    this.registerLoginLink(submitArgs)
  }

  registerPasswordValidation($w) {
    const password = passwordField($w)

    if (!password) return

    password.onCustomValidation((value, reject) => {
      if (this.firstInitialization[FIELDS.ROLE_FIELD_REGISTRATION_FORM_PASSWORD]) {
        this.firstInitialization[FIELDS.ROLE_FIELD_REGISTRATION_FORM_PASSWORD] = false
        return
      }

      if (value.length < PASSWORD_LENGTH.MIN || value.length > PASSWORD_LENGTH.MAX) { //tslint:disable-line
        const defaultErrorMessage = `Password length must be between ${PASSWORD_LENGTH.MIN} and ${
          PASSWORD_LENGTH.MAX
        } characters.`
        reject(
          translations.t('registrationForm.passwordLimitError', {
            min: PASSWORD_LENGTH.MIN,
            max: PASSWORD_LENGTH.MAX,
            defaultValue: defaultErrorMessage,
          })
        )
      }
    })
  }

  registerLoginLink({ $w, wixUsers, wixWindow }) {
    const loginLink = linkLoginField($w)
    if (!loginLink)
      return
    loginLink.onClick(() =>
      wixUsers.promptLogin({ mode: 'login' }).then(() => wixWindow.lightbox.close()),
    )
  }

  async execute({ attachments, fields, viewMode, experiments }): Promise<ServerResponse> {
    if (viewMode === PREVIEW_MODE) {
      return Promise.resolve({ ok: true })
    }

    const { $w, wixUsers, wixWindow, $message } = this.submitArgs
    const { email, password, joinTheCommunityCheckbox } = getFields($w)
    const privacyStatus = joinTheCommunityCheckbox && joinTheCommunityCheckbox.checked ? 'PUBLIC' : 'PRIVATE'
    const payload = { defaultFlow: true, privacyStatus }

    const shouldSendSubmission = experiments.enabled(
      'specs.cx.FormBuilderCustomSignupWithFormServer'
    )

    const ignoredFields = shouldSendSubmission ? IGNORED_FIELDS_WITHOUT_EMAIL : IGNORED_FIELDS
    const validFields = fields.filter(
      field =>
        field.connectionConfig.crmType &&
        !_.isEmpty(getInputValue(field)) &&
        !ignoredFields[field.connectionConfig.fieldType]
    )

    if (shouldSendSubmission) {
      await super.execute({ attachments, fields: submitFields(validFields) })
    }
    
    const contactInfo = buildContactInfo(validFields)
    payload['contactInfo'] = contactInfo
    
    try {
      await wixUsers.register(email.value, password.value, payload)
      wixWindow.lightbox.close()
    } catch (e) {
      Raven.captureException(new Error('Something went wrong while attempting to register'))
      await showExistingAccountMessage($message, {
        messageContent: translations.t('registrationForm.generalError', {
          defaultValue: 'A member with this email address already exists.',
        }),
      })
      return
    }

    return super.execute({
      attachments,
      fields: submitFields(validFields),
      skipSendActivity: true,
    })
  }

  async postSubmission() {
    const { wixWindow } = this.submitArgs
    setTimeout(() => wixWindow.lightbox.close(), 750)
  }
}
